import React from 'react'
import Layout from '../../components/layout'
import Header from '../../components/Header'
import ISO from '../../images/ISOTransparent.png'
import REACH from '../../images/Reach.png'
import Success from '../../images/success.png'
import Mission from '../../images/mission.png'
import { Helmet } from 'react-helmet'
import Partners from '../../components/Partners/Partners'

const ApieMus = () => (
  <Layout>
    <Helmet>
      <title>Apie IN-ECO</title>
    </Helmet>
    <Header styleName="header" title="Apie mus" />
    <div className="content">
      <div className="columns content-row">
        <div className="column content-text">
          <div className="title-small content-title">Patirtis ir gamyba</div>
          UAB „Inžinerinė ekologija“ - specializuota inžinerinė chemijos
          kompanija, kurios specialistai turi ilgametę patirtį diegiant cheminio
          vandens apdirbimo programas bei vykdant jų techninę priežiūrą įvairių
          pramonės sričių įmonėse. Kompanija ruošia individualias vandens
          apdirbimo programas IN-ECO<sup>®</sup>, kurių pagalba sprendžiamos
          korozijos, nuovirų susidarymo, mikrobiologinio apaugimo ir kt.
          problemos.
          <br />
          <br />
          Kompanijos veiklos sritys: cheminiai technologiniai procesai vandens
          valymo ir apdirbimo srityje, dujų ir naftos gavyboje, cukraus,
          popieriaus perdirbimo pramonėje ir kt.
          <br />
          <br />
          Aukšta cheminių reagentų IN-ECO<sup>®</sup> gamybos proceso kokybė
          patvirtinta sertifikatais ISO 9001 ir ISO 14001 bei REACH reglamentu.
        </div>
        <div className="column content-img">
          <img src={ISO} className="iso-img" style={{ width: '50%' }} alt="" />
          <img src={REACH} className="iso-img" alt="" />
        </div>
      </div>
      <div className="columns content-row">
        <div className="column content-img">
          <img src={Mission} alt="" />
        </div>
        <div className="column content-text">
          <div className="title-small content-title">Kompanijos misija:</div>
          Užtikrinti kokybiškos cheminės produkcijos IN-ECO<sup>®</sup>,
          sukurtos remiantis naujausiomis technologijomis ir tenkinančios
          griežčiausius vartotojo reikalavimus, tiekimą.
          <br />
          <br />
          Teikti inovacinius inžinerinius sprendimus pagrindinių gamybinių
          procesų optimizavimui.
        </div>
      </div>
      <div className="columns content-row">
        <div className="column content-text">
          <div className="title-small content-title">Sėkmės principai</div>
          <ul>
            <li className="content-text">
              Patyrusi chemijos inžinierių komanda, turinti profesionalių žinių
              apie technologinius procesus pramonėje.
            </li>
            <li className="content-text">
              Individualus požiūris į kiekvieną klientą, išsami sistemos ir
              proceso veiklos analizė.
            </li>
            <li className="content-text">
              Reguliarus bendravimas su klientais ir techninė pagalba užtikrina
              nuolatinę programų stebėseną bei savalaikį koregavimą, kad būtų
              garantuotas nepriekaištingas pramoninis procesas.
            </li>
          </ul>
        </div>
        <div className="column content-img">
          <img src={Success} alt="" />
        </div>
      </div>
      <div className="title-small content-title">Mūsų partneriai</div>
      <Partners />
    </div>
  </Layout>
)

export default ApieMus
